import React, { Component } from "react";
import { Link } from "gatsby";
import MagicGrid from "magic-grid-react";
import styles from "./index.module.scss";

class ProductGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   articleDisplayCount: 0,
      //   articleDisplayCount: 4
    };
    this.handleImageLoad = this.handleImageLoad.bind(this);
  }
  loadMore() {
    // const { articleDisplayCount } = this.state;
    // this.setState({ articleDisplayCount: articleDisplayCount + 8 });
  }
  handleImageLoad() {
    setTimeout(() => {
      this.shopGrid.positionItems();
    }, 100);
  }
  render() {
    const { limit = null, title = "Shop", shopMore = false } = this.props;
    const { products: productsFull } = this.props;

    const productsLimited = productsFull.slice(0, 40).map((product, idx) => {
      if (limit && idx > limit) {
        return false;
      }
      return product;
    });

    const products = limit ? productsLimited : productsFull;

    return (
      <section id="shop" className={styles.productsGrid}>
        <h2>{title}</h2>
        <ul className={styles.productsGridList}>
          <MagicGrid
            animate={true}
            ref={(element) => {
              this.shopGrid = element;
            }}
          >
            {products.map((product, idx) => {
              const { title, variants, images, priceRange } = product;
              const variantsOnSale =
                (variants &&
                  variants.filter((variant) => {
                    return variant.compareAtPrice !== null;
                  })) ||
                [];
              const onSale = variantsOnSale.length > 0;
              return (
                <li key={idx} className={styles.productsGridListItem}>
                  <Link to={`/shop/${product.handle}/`}>
                    <div>
                      {images && (
                        <img
                          src={images[0].originalSrc}
                          alt={product.title}
                          title={product.title}
                          onLoad={this.handleImageLoad}
                        />
                      )}
                    </div>
                    <h3>
                      <>
                        {title}
                        {priceRange && (
                          <div className={styles.productsGridListItemPrice}>
                            ${Math.round(priceRange.minVariantPrice.amount)}
                          </div>
                        )}
                      </>
                    </h3>
                  </Link>
                </li>
              );
            })}
          </MagicGrid>
        </ul>
        {limit && shopMore && (
          <div className={styles.productsGridShopMore}>
            <Link to="/shop">Shop More</Link>
          </div>
        )}
      </section>
    );
  }
}

export default ProductGrid;
